<template>
  <div>
    <v-form ref="form">
      <div style="font-size: 14px">
        <strong class="asterisk-text">
          是否免邮费
        </strong>
        <div class="d-flex align-center">
          <div class="input-width">
            <w-autocomplete
                :items="selectItems"
                label="实付金额"
                :value="sysForm.type"
                @change="sysForm.type = $event"
            />
          </div>
          <div class="d-flex align-center" v-if="sysForm.type !== '0'">
            <div style="margin: 0 10px">大于</div>
            <div class="input-width">
              <v-text-field
                  v-if="sysForm.type === '1'"
                  v-model="sysForm.pprice"
                  label="免邮金额"
              />
              <v-text-field
                  v-if="sysForm.type === '2'"
                  v-model="sysForm.oprice"
                  label="免邮金额"
              />
            </div>
            <div class="ml-1">
              元时免邮费
            </div>
          </div>
        </div>
      </div>
      <div>
        <strong class="asterisk-text">
          默认邮费设置
        </strong>
        <div class="d-flex align-center">
          <div class="input-width">
            <v-text-field label="全国默认邮费" v-model="sysForm.amount"/>
          </div>
          <div class="ml-1">元</div>
        </div>
      </div>
      <div>
        <strong class="asterisk-text">
          区域自定义邮费
        </strong>
      </div>
      <div style="font-size: 14px; color: #999999; margin-top: 8px">
        添加自定义运费后，优先按照自定义运费计算，未设置的区域按默认运费计算
      </div>
    </v-form>
    <div class="mb-2 text-right">
      <v-btn depressed color="primary" @click="openDialog(true)">新建</v-btn>
    </div>
    <div class="mb-8">
      <common-table
          :headers="headers"
          :listData="listData"
          :loading="loading"
          :btnAction="true"
          @remove="remove"
          @save="save"
          :fixedHeight="tableHeight"
          :showPagination="false"
          :btnActionEvent="btnActionEvent"
          ref="ct"
          pageTitle="区域"
          pageKey="name"
          @otherTableOperate="otherTableOperate"
      />
    </div>
    <exhibition-data-box
        :dialog.sync="dialog"
        :title="dialogTitle"
        :width="408"
        :contentHeight="360"
    >
      <v-form class="d-flex flex-wrap" ref="form">
        <v-text-field
            v-model="form.name"
            class="col-6 asterisk"
            label="区域名称"
            :rules="[v => !!v || '区域名称不能为空']"/>
        <v-text-field
            class="col-6 asterisk"
            v-model="form.amount"
            label="配送金额"
            :rules="[v => !!v || '配送金额不能为空']"/>
        <w-autocomplete
            @change="form.address = $event"
            :items="province"
            :multiple="true"
            :chips="true"
            :arrayType="false"
            class="col-12 asterisk"
            :required="true"
            label="选择区域"
            :value="form.address"
            :rules="[v => !!form.address || '选择区域不能为空']"/>
        <v-radio-group v-model="form.type">
          <v-radio label="不包邮" value="0"/>
          <v-radio class="mt-2" value="1">
            <template v-slot:label>
              <div class="d-flex align-center">
                <div>
                  实付金额 大于
                </div>
                <div style="margin: 0 10px; width: 120px;">
                  <v-text-field hide-details v-model="form.pprice" :disabled="form.type !== '1'"/>
                </div>
                <div>元时免邮费</div>
              </div>
            </template>
          </v-radio>
          <v-radio value="2">
            <template v-slot:label>
              <div class="d-flex align-center">
                <div>
                  原价金额 大于
                </div>
                <div style="margin: 0 10px; width: 120px;">
                  <v-text-field hide-details v-model="form.oprice" :disabled="form.type !== '2'"/>
                </div>
                <div>元时免邮费</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-form>
      <template #footer>
        <div class="text-center">
          <v-btn depressed color="primary" outlined @click="dialog = false">取消</v-btn>
          <v-btn class="ml-8" depressed color="primary" @click="saveTable">确定</v-btn>
        </div>
      </template>
    </exhibition-data-box>
    <v-footer
        style="background: #ffffff; padding: 20px 24px"
        padless
        absolute
    >
      <v-btn @click="save" depressed color="primary">保存</v-btn>
    </v-footer>
  </div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from "@/api/productManage/hand-greeting/freight-setting.js";
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box.vue";

export default {
  mixins: [commonCurd],
  components: {
    wAutocomplete,
    exhibitionDataBox
  },
  data() {
    return {
      api,
      headers: [
        {text: '区域名称', value: 'name'},
        {text: '配送地址', value: 'address'},
        {text: '配送金额（元）', value: 'amount'},
        {text: '邮费类型', value: 'type'},
        {text: '操作', value: 'actions', width: 120}
      ],
      tableHeight: 200,
      sysForm: {
        type: '',
        amount: '',
        oprice: '',
        pprice: ''
      },
      selectItems: [],
      dialog: false,
      dialogTitle: '新建区域',
      province: [],
      form: {
        name: '',
        amount: '',
        oprice: '',
        pprice: '',
        type: '0',
        address: '',
        sqlid: ''
      },
      btnActionEvent: [
        {
          event: 'editItem1',
          icon: 'bianji2-fill',
          color: 'primary',
          tooltip: '编辑'
        }
        , {
          event: 'deleteItem',
          icon: 'shanchu-fill',
          color: 'var(--v-redness-base)',
          tooltip: '删除'
        }
      ]
    }
  },
  methods: {
    save() {
      this.axios.post(api.save, this.sysForm).then(res => {
        if (res.code === this.staticVal.Code.Success) {
          this.snackbar.success("保存成功！");
        }
      })
    },
    saveTable() {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.axios.post(api.save, this.form).then(res => {
          if (res.code === this.staticVal.Code.Success) {
            this.snackbar.success("保存成功！");
            this.dialog = false
            this.list()
          }
        })
      }
    },
    openDialog(add, item) {
      this.dialog = true
      if (add) {
        this.form = {
          name: '',
          amount: '',
          oprice: '',
          pprice: '',
          type: '0',
          address: '',
          sqlid: 0
        }
      } else {
        this.form = item
      }
    },
    otherTableOperate(event, item) {
      if (event === this.btnActionEvent[0].event) {
        this.axios.post(api.load, {sqlid: item.sqlid}).then(res => {
          this.openDialog(false, res.data)
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 250 - 56 - 72 - 12 * 2 - 40
    })
    this.axios.post(api.postage_sys).then(res => {
      this.sysForm = res.data
    })

    this.axios.post(this.select_data, {keyname: 'postagetype,PROVINCE'}).then(res => {
      this.selectItems = res.data[0].values
      this.province = res.data[1].values
    })
  }
}
</script>

<style scoped lang="scss">
.asterisk-text {
  color: #666666;
  font-size: 18px;
}

.input-width {
  width: 140px;
}

::v-deep {
  .v-input--selection-controls__ripple {
    width: 0;
  }
}
</style>
